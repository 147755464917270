import { ref } from "vue";
import CloudFun from "@cloudfun/core";
import type { Customer, CustomerGrade, Employee, Product } from "@/models/data";

type SelectOption = {
  label: string;
  value: string;
};

const model = CloudFun.current?.model;
const customerGrades = ref<SelectOption[]>([]);
const employees = ref<SelectOption[]>([]);
const products = ref<SelectOption[]>([]);
const percentages = ref<SelectOption[]>(
  model?.getters["constant/discountPercentages"]
);

const findCustomer = async (id: string): Promise<Customer> =>
  await model?.dispatch("customer/find", id).then((value) => value);

const fetch = async (): Promise<void> => {
  customerGrades.value = await model
    ?.dispatch("customerGrade/queryAll")
    .then((value) =>
      value.payload.map((e: CustomerGrade) => ({
        label: e.Name,
        value: e.Id,
      }))
    );

  employees.value = await model?.dispatch("employee/queryAll").then((value) =>
    value.payload.map((e: Employee) => ({
      label: e.Name,
      value: e.Id,
    }))
  );

  products.value = await model?.dispatch("product/queryAll").then((value) =>
    value.payload.map((e: Product) => ({
      label: e.Name,
      value: e.Id,
    }))
  );
};

export {
  model as dataModel,
  findCustomer,
  fetch,
  customerGrades,
  employees,
  products,
  percentages,
};
