import XEUtils from "xe-utils";

const isOrderFinish = (status: number): boolean => status === 31 || status === 15;
const summarizeAmount = (
  field: string,
  data: { [key: string]: unknown }[]
): string =>
  field && data
    ? XEUtils.commafy(XEUtils.sum(data.map((row) => row[field])))
    : "0";

export { isOrderFinish, summarizeAmount };
